<template>
  <div>
    <v-container fluid>
      <v-dialog v-model="dialogDetail" width="600px" persistent>
        <v-card tile>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined small tile @click="dialogDetail = false">
              Close
            </v-btn>
          </v-card-actions>
          <v-card-title>
            <v-spacer></v-spacer>
            <span class="caption text-decoration-overline"
              >Details of AA/<span
                v-for="(dept, index) in filterDept(caseDetail.refType)"
                :key="index"
                >{{ dept.name }}
                <span
                  v-for="(subRef, index) in filterSubDept(
                    caseDetail.subRefType
                  )"
                  :key="index"
                  >/{{ subRef.subCode }}</span
                > </span
              >/{{ caseDetail.id }}/{{ yearOnly(caseDetail.createdAt) }}/<span
                v-for="branch in filterBranch(caseDetail.branch)"
                :key="branch.id"
                >{{ branch.brCode }}
              </span>
            </span>
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center font-weight-black">Name</th>
                  <th class="text-left font-weight-black">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="caseDetail.fileStatus">
                  <td class="text-center">Status</td>
                  <td
                    v-for="fileStatus in filterStages(caseDetail.fileStatus)"
                    :key="fileStatus.id"
                    class="text-left font-italic font-weight-black"
                  >
                    {{ fileStatus.name }}
                  </td>
                </tr>
                <tr v-if="caseDetail.createdAt">
                  <td class="text-center">Created On:</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.createdAt) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.createdAt">
                  <td class="text-center">Created By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.createdBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.transferDate">
                  <td class="text-center">Transfer Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.transferDate) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.transferDate">
                  <td class="text-center">Transfered By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.transferBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfCancel">
                  <td class="text-center">Cancellation Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfCancel) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfCancel">
                  <td class="text-center">Cancellation By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.cancelBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.caseFrom">
                  <td class="text-center">Type</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.caseFrom }}
                  </td>
                </tr>

                <tr v-if="caseDetail.refType">
                  <td class="text-center">Department</td>
                  <td class="text-left font-italic">
                    {{ filterDept(caseDetail.refType)[0].name }}, ({{
                      filterDept(caseDetail.refType)[0].description
                    }})
                  </td>
                </tr>
                <tr v-if="caseDetail.subRefType">
                  <td class="text-center">File Classification</td>
                  <td class="text-left font-italic">
                    {{ filterSubDept(caseDetail.subRefType)[0].subCode }}
                  </td>
                </tr>
                <tr v-if="caseDetail.branch">
                  <td class="text-center">Branch</td>
                  <td class="text-left font-italic">
                    {{ filterBranch(caseDetail.branch)[0].brCode }}, ({{
                      filterBranch(caseDetail.branch)[0].name
                    }})
                  </td>
                </tr>
                <tr v-if="caseDetail.insurer">
                  <td class="text-center">Insurer</td>
                  <td class="text-left font-italic">
                    {{ filterIns(caseDetail.insurer)[0].name }}
                  </td>
                </tr>
                <tr v-if="caseDetail.handler">
                  <td class="text-center">Handler</td>
                  <td class="text-left font-italic">
                    {{ filterHandler(caseDetail.handler)[0].name }}, ({{
                      filterHandler(caseDetail.handler)[0].email
                    }})
                  </td>
                </tr>
                <tr v-if="caseDetail.adjuster">
                  <td class="text-center">Adjuster</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.adjuster) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.editor">
                  <td class="text-center">Editor</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.editor) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.clerkInCharge">
                  <td class="text-center">Clerk In Charge</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.clerkInCharge) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.claimNo">
                  <td class="text-center">Insurer's Ref No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.claimNo }}
                  </td>
                </tr>
                <tr v-if="caseDetail.vehicleNo">
                  <td class="text-center">Vehicle No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.vehicleNo }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfAssign">
                  <td class="text-center">Date Of Assignment</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfAssign) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfLoss">
                  <td class="text-center">Date Of Loss</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfLoss) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.stateOfLoss">
                  <td class="text-center">State Of Loss</td>
                  <td class="text-left font-italic">
                    {{ filterState(caseDetail.stateOfLoss)[0].stCode }}, ({{
                      filterState(caseDetail.stateOfLoss)[0].name
                    }})
                  </td>
                </tr>
                <tr v-if="caseDetail.placeOfLoss">
                  <td class="text-center">Place Of Loss</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.placeOfLoss }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insuredName">
                  <td class="text-center">Insured Name</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insuredName }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insuredIC">
                  <td class="text-center">Insured IC No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insuredIC }}
                  </td>
                </tr>
                <tr v-if="caseDetail.InsuredTel">
                  <td class="text-center">Insured Tel. No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.InsuredTel }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insuredEmail">
                  <td class="text-center">Insured Email</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insuredEmail }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insuredDriverName">
                  <td class="text-center">Insured Driver Name</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insuredDriverName }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insuredDriverIc">
                  <td class="text-center">Insured Driver IC No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insuredDriverIc }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insuredDriverEmail">
                  <td class="text-center">Insured Driver Email</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insuredDriverEmail }}
                  </td>
                </tr>
                <tr v-if="caseDetail.policeReportNo">
                  <td class="text-center">Police Report No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.policeReportNo }}
                  </td>
                </tr>
                <tr v-if="caseDetail.policeReportDate">
                  <td class="text-center">Police Report Date</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.policeReportDate }}
                  </td>
                </tr>
                <tr v-if="caseDetail.policyNo">
                  <td class="text-center">Policy Number</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.policyNo }}
                  </td>
                </tr>
                <tr v-if="caseDetail.coverFrom || caseDetail.coverTo">
                  <td class="text-center">Cover Period</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.coverFrom) }} -
                    {{ formatDate(caseDetail.coverTo) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.insComment">
                  <td class="text-center">Insurer's Comment</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.insComment }}
                  </td>
                </tr>
                <tr v-if="caseDetail.solicitorName">
                  <td class="text-center">Solicitor Name</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.solicitorName }}
                  </td>
                </tr>
                <tr v-if="caseDetail.solicitorTel">
                  <td class="text-center">Solicitor Tel.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.solicitorTel }}
                  </td>
                </tr>
                <tr v-if="caseDetail.solicitorEmail">
                  <td class="text-center">Solicitor Email</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.solicitorEmail }}
                  </td>
                </tr>
                <tr v-if="caseDetail.counselName">
                  <td class="text-center">Counsel Name</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.counselName }}
                  </td>
                </tr>
                <tr v-if="caseDetail.counselTel">
                  <td class="text-center">Counsel Tel</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.counselTel }}
                  </td>
                </tr>
                <tr v-if="caseDetail.counselEmail">
                  <td class="text-center">Counsel Email</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.counselEmail }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantName">
                  <td class="text-center">Third Party Claimant Name</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantName }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantIc">
                  <td class="text-center">Third Party Claimant IC No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantIc }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantTel">
                  <td class="text-center">Third Party Claimant Tel No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantTel }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantEmail">
                  <td class="text-center">Third Party Claimant Email</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantEmail }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantVehicleNo">
                  <td class="text-center">Third Party Claimant Vehicle No.</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantVehicleNo }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantVehicleModel">
                  <td class="text-center">
                    Third Party Claimant Vehicle Model
                  </td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantVehicleModel }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantVehicleSolicitor">
                  <td class="text-center">Third Party Claimant Solicitor</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantVehicleSolicitor }}
                  </td>
                </tr>
                <tr v-if="caseDetail.thirdPartyClaimantVehicleSolicitorEmail">
                  <td class="text-center">
                    Third Party Claimant Solicitor Email
                  </td>
                  <td class="text-left font-italic">
                    {{ caseDetail.thirdPartyClaimantVehicleSolicitorEmail }}
                  </td>
                </tr>

                <tr v-if="caseDetail.applyDocDate">
                  <td class="text-center">Document Application Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.applyDocDate) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.applyDocDate">
                  <td class="text-center">Document Application By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.applyDocBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfReg">
                  <td class="text-center">Branch Assigning Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfReg) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfReg">
                  <td class="text-center">Branch Assigned By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.assignBranchBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfAdj">
                  <td class="text-center">Adjuster Assigning Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfAdj) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfAdj">
                  <td class="text-center">Adjuster Assigning By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.assignAdjBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateStartInv">
                  <td class="text-center">Start Investigation Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateStartInv) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateEndInv">
                  <td class="text-center">Complete Investigation Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateEndInv) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfEdi">
                  <td class="text-center">Editor Assigned Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfEdi) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfEdi">
                  <td class="text-center">Editor Assigned By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.assignEdiBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateStartEdi">
                  <td class="text-center">Start Editing Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateStartEdi) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateEndEdi">
                  <td class="text-center">Complete Editing Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateEndEdi) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfApproval">
                  <td class="text-center">Approval Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateOfApproval) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateOfApproval">
                  <td class="text-center">Approval By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.approvedBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateAssignFormatting">
                  <td class="text-center">Clerk Assigned Date(Formatting)</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateAssignFormatting) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateAssignFormatting">
                  <td class="text-center">Clerk Assigned (Formatting) By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.assignClerkBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateStartFormatting">
                  <td class="text-center">Start Formatting Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateStartFormatting) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateEndFormatting">
                  <td class="text-center">Complete Formatting Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateEndFormatting) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateFinal">
                  <td class="text-center">Invoising & Finalisation Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateFinal) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateFinal">
                  <td class="text-center">Invoising & Finalisation By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.finalBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateFinal">
                  <td class="text-center">Invoice Nombor</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.invNo }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateFinal">
                  <td class="text-center">Invoice Fee Note</td>
                  <td class="text-left font-italic">
                    {{ caseDetail.invFeeNote }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateFinal">
                  <td class="text-center">Invoice Total</td>
                  <td class="text-left font-italic">
                    RM {{ caseDetail.invTotal }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateDispatch">
                  <td class="text-center">Dispatch Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateDispatch) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateDispatch">
                  <td class="text-center">Dispatch By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.dispatchBy) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateClosed">
                  <td class="text-center">Closed Date</td>
                  <td class="text-left font-italic">
                    {{ formatDate(caseDetail.dateClosed) }}
                  </td>
                </tr>
                <tr v-if="caseDetail.dateClosed">
                  <td class="text-center">Closed By:</td>
                  <td class="text-left font-italic">
                    {{ filterUser(caseDetail.closedBy) }}
                  </td>
                </tr>

                <!-- <tr v-for="(item, index) in caseDetail" :key="index">
                <td>{{ index }}</td>
                <td>{{ item }}</td>
              </tr> -->
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined small tile @click="dialogDetail = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        dense
        :headers="headers"
        :items="casefiles"
        class="elevation-1 pt-1"
        :loading="loadTable"
        loading-text="Working on it ......"
        :search="search"
        @current-items="currentItems"
        @click:row="(item) => showDetail(item)"
        style="cursor: pointer"
        mobile-breakpoint="1100"
      >
        <template v-slot:top>
          <v-row style="border: 1px dotted orange" class="ma-1" no-gutters>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-autocomplete
                clearable
                v-model="insurerColumn"
                :items="insurer"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Insurer"
                persistent-hint
                label="Insurer"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-2 d-md-none justify-center text-center">
              <v-autocomplete
                clearable
                v-model="insurerColumn"
                :items="insurer"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Insurer"
                persistent-hint
                label="Insurer"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-text-field
                clearable
                v-model="vehicleNoColumn"
                type="text"
                label="Vehicle No."
              ></v-text-field
            ></v-col>
            <v-col cols="6" class="pa-2 d-md-none justify-center text-center">
              <v-text-field
                clearable
                v-model="vehicleNoColumn"
                type="text"
                label="Vehicle No."
              ></v-text-field
            ></v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-text-field
                clearable
                v-model="aasbidColumn"
                type="text"
                label="AASB Ref."
              ></v-text-field
            ></v-col>
            <v-col cols="6" class="pa-2 d-md-none justify-center text-center">
              <v-text-field
                clearable
                v-model="aasbidColumn"
                type="text"
                label="AASB Ref."
              ></v-text-field
            ></v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :value="computedDateFormattedMomentjs"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                    label="Start Date"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :value="computedDateFormattedMomentjs"
                  @change="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pa-2 d-md-none justify-center text-center">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :value="computedDateFormattedMomentjs"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                    label="Start Date"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :value="computedDateFormattedMomentjs"
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    :value="computedDateFormattedMomentjs"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                    label="End Date"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :value="computedDateFormattedMomentjs"
                  @change="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pa-2 d-md-none justify-center text-center">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    :value="computedDateFormattedMomentjs"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                    label="End Date"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :value="computedDateFormattedMomentjs"
                  @change="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- {{ "Branch:" + branch[0].brCode }} -->
          </v-row>
          <v-row style="border: 1px dotted orange" class="ma-1" no-gutters>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-autocomplete
                clearable
                v-model="branchColumn"
                :items="branch"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Branch"
                persistent-hint
                label="Branch"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-2 d-md-none justify-center text-center">
              <v-autocomplete
                clearable
                v-model="branchColumn"
                :items="branch"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Branch"
                persistent-hint
                label="Branch"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-autocomplete
                clearable
                v-model="refTypeColumn"
                :items="refType"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Department"
                persistent-hint
                label="Department"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-2 d-md-none justify-center text-center">
              <v-autocomplete
                clearable
                v-model="refTypeColumn"
                :items="refType"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Department"
                persistent-hint
                label="Department"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-autocomplete
                clearable
                v-model="subRefTypesColumn"
                :items="subRefTypes"
                item-text="subCode"
                item-value="subCode"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by File Classification"
                persistent-hint
                label="File Classification"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-2 d-md-none justify-center text-center">
              <v-autocomplete
                clearable
                v-model="subRefTypesColumn"
                :items="subRefTypes"
                item-text="subCode"
                item-value="subCode"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by File Classification"
                persistent-hint
                label="File Classification"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-autocomplete
                clearable
                v-model="adjusterColumn"
                :items="filterAdjByBranch(branchColumn)"
                item-text="username"
                item-value="username"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Adjuster"
                persistent-hint
                label="Adjuster"
                no-data-text="Select Branch"
              >
              </v-autocomplete>
              <!-- <v-autocomplete
                clearable
                v-model="adjusterColumn"
                :items="adjusters"
                item-text="username"
                item-value="username"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Adjuster"
                persistent-hint
                label="Adjuster"
              >
              </v-autocomplete> -->
            </v-col>
            <v-col cols="12" class="pa-2 d-md-none justify-center text-center">
              <v-autocomplete
                clearable
                v-model="adjusterColumn"
                :items="filterAdjByBranch(branchColumn)"
                item-text="username"
                item-value="username"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Adjuster"
                persistent-hint
                label="Adjuster"
                no-data-text="Select Branch"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              xsm="12"
              class="pa-2 d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <v-autocomplete
                clearable
                v-model="statusColumn"
                :items="stages"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Status"
                persistent-hint
                label="Status"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-2 d-md-none justify-center text-center">
              <v-autocomplete
                clearable
                v-model="statusColumn"
                :items="stages"
                item-text="name"
                item-value="name"
                :menu-props="{ maxHeight: '400' }"
                hint="Filter by Status"
                persistent-hint
                label="Status"
              >
              </v-autocomplete>
            </v-col>
            <!-- EXPORT BUTTON -->
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              class="d-none d-xsm-none d-sm-none d-md-flex justify-center"
            >
              <vue-excel-xlsx
                :data="currentCasefiles"
                :columns="columns"
                :file-name="`${moment().format(`DD/MM/YYYY`)}`"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
              >
                <v-tooltip left transition="fab-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab small v-bind="attrs" v-on="on" class="success">
                      <v-icon>mdi-file-excel-outline</v-icon>
                    </v-btn>
                  </template>
                  <span> EXPORT EXCEL REPORT</span>
                </v-tooltip>
              </vue-excel-xlsx></v-col
            >
            <v-col class="pb-5 d-md-none justify-center text-center">
              <vue-excel-xlsx
                :data="currentCasefiles"
                :columns="columns"
                :file-name="`${moment().format(`DD/MM/YYYY`)}`"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
              >
                <v-tooltip left transition="fab-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      tile
                      v-bind="attrs"
                      v-on="on"
                      class="success"
                      width="250px"
                    >
                      <!-- <v-icon>mdi-file-excel-outline</v-icon> -->EXPORT
                      EXCEL
                    </v-btn>
                  </template>
                  <span> EXPORT XLS</span>
                </v-tooltip>
              </vue-excel-xlsx></v-col
            >
          </v-row>
        </template>
        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>
        <template v-slot:[`item.insurer`]="{ item }">
          <div v-for="ins in filterIns(item.insurer)" :key="ins.id">
            <div class="text-truncate" style="max-width: 150px">
              {{ ins.name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.branch`]="{ item }">
          <div v-for="branch in filterBranch(item.branch)" :key="branch.id">
            <div class="text-truncate" style="max-width: 150px">
              {{ branch.name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.refType`]="{ item }">
          <div v-for="refType in filterDept(item.refType)" :key="refType.id">
            <div class="text-truncate" style="max-width: 150px">
              {{ refType.name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.subRefType`]="{ item }">
          <div
            v-for="subRefType in filterSubDept(item.subRefType)"
            :key="subRefType.id"
          >
            <div class="text-truncate" style="max-width: 150px">
              {{ subRefType.subCode }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.adjuster`]="{ item }">
          <div v-for="adjuster in filterAdj(item.adjuster)" :key="adjuster.id">
            <div class="text-truncate" style="max-width: 150px">
              {{ adjuster.username }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.vehicleNo`]="{ item }">
          <div
            v-if="item.vehicleNo"
            class="text-truncate"
            style="max-width: 100px"
          >
            {{ item.vehicleNo.toUpperCase() }}
          </div>
          <div v-else class="font-italic">NIL</div>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <tr class="d-flex">
            AA
            <div v-for="(dept, index) in filterDept(item.refType)" :key="index">
              /{{ dept.name
              }}<span
                v-for="(subDept, index) in filterSubDept(item.subRefType)"
                :key="index"
                >/{{ subDept.subCode }}</span
              >
            </div>

            /{{
              item.id
            }}/{{
              yearOnly(item.createdAt)
            }}
            <div v-for="branch in filterBranch(item.branch)" :key="branch.id">
              /{{ branch.brCode }}
            </div>
          </tr>
        </template>

        <template v-slot:[`item.dateOfAssign`]="{ item }">
          {{ formatDate(item.dateOfAssign) }}
        </template>

        <template v-slot:[`item.days`]="{ item }">
          {{ dateDiff(moment(item.dateOfAssign).format(), moment().format()) }}
        </template>
        <!-- <template v-slot:[`item.days`]="{ item }">
          {{ dateDiff(moment(item.dateOfAssign).format(), moment().format()) }}
          Day(s)
        </template> -->

        <template v-slot:[`item.fileStatus`]="{ item }">
          <span
            v-for="allStatus in filterStages(item.fileStatus)"
            :key="allStatus.id"
            v-bind="allStatus.item"
          >
            <v-chip label outlined block x-small>
              {{ allStatus.name }}
            </v-chip>
          </span>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="showDetail(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <span> View</span>
          </v-tooltip>
        </template> -->
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import BranchDataService from "../../services/BranchDataService";
import StateDataService from "../../services/StateDataService";
import RegionDataService from "../../services/RegionDataService";
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
import CasefileDataService from "../../services/CasefileDataService";
import StagesDataService from "../../services/StageDataService";
import HandlerDataService from "../../services/HandlerDataService";
import MemberDataService from "../../services/MemberDataService";

import moment from "moment";

export default {
  name: "reportsTwo",
  components: {},

  data() {
    return {
      loadTable: true,
      columns: [
        // {
        //   label: "NO",
        //   field: "id",
        // },
        {
          label: "CLAIMS HANDLER",
          field: "handler",
          dataFormat: this.showHandlerName,
        },
        {
          label: "INSURER'S REF",
          field: "claimNo",
        },
        {
          label: "INSURED NAME",
          field: "insuredName",
          dataFormat: this.upperCaseVehicleNo,
        },
        {
          label: "VEHICLE NO",
          field: "vehicleNo",
          dataFormat: this.upperCaseVehicleNo,
        },
        {
          label: "INSURER",
          field: "insurer",
          dataFormat: this.showInsurerName,
        },
        {
          label: "CLAIM TYPE",
          field: "refType",
          dataFormat: this.filterDeptOnly,
        },
        {
          label: "BRANCH",
          field: "branch",
          dataFormat: this.showBranchName,
        },
        {
          label: "DEPARTMENT",
          field: "refType",
          dataFormat: this.showRefTypeName,
        },
        {
          label: "FILE CLASSIFICATION",
          field: "subRefType",
          dataFormat: this.showsubRefTypeName,
        },
        {
          label: "ADJUSTER",
          field: "adjuster",
          dataFormat: this.showAdjusterName,
        },
        {
          label: "AASB REF",
          field: "id",
          dataFormat: this.showAasbRef,
        },
        {
          label: "DATE OF ASSIGNMENT",
          field: "dateOfAssign",
          dataFormat: this.formatDate,
        },
        {
          label: "DATE OF LOSS",
          field: "dateOfLoss",
          dataFormat: this.formatDate,
        },
        {
          label: "DAYS",
          field: "dateOfAssign",
          dataFormat: this.showDateDiff,
        },
        {
          label: "ADJUSTER ACKNOWLEDMENT DATE",
          field: "dateStartInv",
          dataFormat: this.formatDate,
        },
        {
          label: "REPORT SUBMISSION DATE",
          field: "dateEndInv",
          dataFormat: this.formatDate,
        },

        {
          label: "STATUS",
          field: "fileStatus",
          dataFormat: this.showFileStatus,
        },
        {
          label: "TOTAL AMOUNT OF ALL TAX INVOICES",
          field: "invTotal",
        },
      ],
      // excel_fields: {
      //   No: "id",
      //   // "Claims Handler": "handler",
      //   "Claims Handler": {
      //     field: "handler",
      //     callback: (value) => {
      //       const handlerName = this.filterHandler(value);
      //       if (!value) return undefined;
      //       return handlerName[0].name + "";
      //       // return `Landline Phone - ${value}`;
      //     },
      //   },
      //   // INSURER: "insurer",
      //   INSURER: {
      //     field: "insurer",
      //     callback: (value) => {
      //       const insurerName = this.filterIns(value);
      //       if (!value) return undefined;
      //       return insurerName[0].name + "";
      //       // return `Landline Phone - ${value}`;
      //     },
      //   },
      //   VEHICLE: "vehicleNo",
      //   // STATUS: "fileStatus",
      //   STATUS: {
      //     field: "fileStatus",
      //     callback: (value) => {
      //       const stageName = this.filterStages(value);
      //       if (!value) return undefined;
      //       return stageName[0].name + "";
      //       // return `Landline Phone - ${value}`;
      //     },
      //   },
      //   // "Telephone 2": {
      //   //   field: "phone.landline",
      //   //   callback: (value) => {
      //   //     return `Landline Phone - ${value}`;
      //   //   },
      //   // },
      // },
      search: "",
      insurerColumn: "",
      branchColumn: "",
      refTypeColumn: "",
      subRefTypesColumn: "",
      adjusterColumn: "",
      statusColumn: "",
      vehicleNoColumn: "",
      aasbidColumn: "",
      claimNoColumn: "",
      casefiles: [],
      currentCasefiles: [],
      branch: [],
      states: [],
      regions: [],
      refType: [],
      subRefTypes: [],
      stages: [],
      insurer: [],
      handlers: [],
      caseDetail: [],
      members: [],
      adjusters: [],
      startDate: null,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      endDate: null,
      dialogDetail: false,
    };
  },
  computed: {
    filteredCasefiles() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN")
      ) {
        return this.casefiles;
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      ) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch)
        );
      } else if (this.$store.state.auth.user.roles.includes("ADJUSTER")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id.toString().includes(x.adjuster)
        );
      } else if (this.$store.state.auth.user.roles.includes("EDITOR")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id.toString().includes(x.editor)
        );
      } else return this.casefiles;
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
    headers() {
      return [
        {
          text: "NO.",
          align: "start",
          sortable: false,
          value: "numbering",
        },
        {
          text: "INSURER",
          align: "start",
          sortable: false,
          value: "insurer",
          filter: (f) => {
            if (!this.insurerColumn) return true;
            const insurerName = this.filterIns(f);
            // console.log(insurerName);
            return (insurerName[0].name + "")
              .toLowerCase()
              .includes(this.insurerColumn.toLowerCase());
          },
        },
        {
          text: "BRANCH",
          align: "start",
          sortable: false,
          value: "branch",
          filter: (f) => {
            if (!this.branchColumn) return true;
            const branchName = this.filterBranch(f);
            // console.log(branchName[0].name);
            // console.log(branchName[0].name);
            return (branchName[0].name + "")
              .toLowerCase()
              .includes(this.branchColumn.toLowerCase());
          },
        },
        {
          text: "DEPARTMENT",
          align: "start",
          sortable: false,
          value: "refType",
          filter: (f) => {
            if (!this.refTypeColumn) return true;
            const refTypeName = this.filterDept(f);
            return (refTypeName[0].name + "")
              .toLowerCase()
              .includes(this.refTypeColumn.toLowerCase());
          },
        },
        {
          text: "FILE CLASSIFICATION",
          align: "start",
          sortable: false,
          value: "subRefType",
          filter: (f) => {
            if (!this.subRefTypesColumn) return true;
            const subRefTypesName = this.filterSubDept(f);
            return (subRefTypesName[0].subCode + "")
              .toLowerCase()
              .includes(this.subRefTypesColumn.toLowerCase());
          },
        },
        {
          text: "ADJUSTER",
          align: "start",
          sortable: false,
          value: "adjuster",
          filter: (f) => {
            if (!this.adjusterColumn) return true;
            const adjusterName = this.filterAdj(f);
            return (adjusterName[0].username + "")
              .toLowerCase()
              .includes(this.adjusterColumn.toLowerCase());
          },
        },
        {
          text: "VEHICLE NO.",
          align: "start",
          value: "vehicleNo",
          filter: (f) => {
            if (!this.vehicleNoColumn) return true;
            return (f + "")
              .toLowerCase()
              .includes(this.vehicleNoColumn.toLowerCase());
          },
        },
        {
          text: "AASB REFERENCE",
          align: "start",
          value: "id",
          filter: (value) => {
            if (!this.aasbidColumn) return true;
            // return value < parseInt(this.aasbidColumn); // to filter number less than
            return value == parseInt(this.aasbidColumn);
          },
        },
        // {
        //   text: "CLAIM NO.",
        //   align: "start",
        //   value: "claimNo",
        //   filter: (f) => {
        //     if (!this.claimNoColumn) return true;
        //     return (f + "")
        //       .toLowerCase()
        //       .includes(this.claimNoColumn.toLowerCase());
        //   },
        // },
        {
          text: "DATE OF ASSIGNMENT",
          align: "start",
          sortable: false,
          value: "dateOfAssign",
          filter: (value) => {
            if (this.startDate && !this.endDate)
              return moment(value) >= moment(this.startDate);
            else if (!this.startDate && this.endDate)
              return moment(value) <= moment(this.endDate);
            else if (this.startDate && this.endDate)
              return (
                moment(value) >= moment(this.startDate) &&
                moment(value) <= moment(this.endDate)
              );
            else return true;
          },
        },
        {
          text: "DAYS",
          align: "start",
          sortable: false,
          value: "days",
        },

        // {
        //   text: "STATUS",
        //   align: "center",
        //   value: "fileStatus",
        //   sortable: false,
        // },
        {
          text: "STATUS",
          align: "center",
          sortable: false,
          value: "fileStatus",
          filter: (f) => {
            if (!this.statusColumn) return true;
            const statusName = this.filterStages(f);
            return (statusName[0].name + "")
              .toLowerCase()
              .includes(this.statusColumn.toLowerCase());
          },
        },

        // {
        //   text: "DETAIL",
        //   align: "center",
        //   value: "actions",
        //   sortable: false,
        // },
      ];
    },
  },

  methods: {
    filterAdjByBranch(item) {
      // if (!item) return "TBA";
      return this.adjusters.filter((r) =>
        r.branches.some((i) => i.name === item)
      );
    },
    showDetail(item) {
      this.caseDetail = Object.assign({}, item);
      this.dialogDetail = true;
    },
    convertDate(value) {
      // Close the date picker.
      this.$refs.menu.save(value);
      //Convert the value to a timestamp before saving it.
      moment(value).format("DD/MM/YYYY");
      // return (this.startDate = moment(value).format("DD/MM/YYYY"));
      // const timestamp = new Date(val + "T00:00:00Z").getTime();
      // this.startDate = timestamp;
    },

    currentItems: function (value) {
      return (this.currentCasefiles = value);
    },
    // startDownload() {
    //   alert("show loading");
    // },
    // finishDownload() {
    //   alert("hide loading");
    // },
    // handleSuccess() {
    //   alert("Export Success");
    // },
    // handleError() {
    //   alert("Error Exporting");
    // },
    retrieveCasefiles() {
      CasefileDataService.getAll()
        .then((response) => {
          this.casefiles = response.data;

          this.loadTable = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveCasefiles();
      this.retrieveIns();
    },

    filterUser(item) {
      // return this.members.filter((member) => member.id === item);
      const myArray = this.members.filter((member) => member.id === item);
      let result = myArray.map((a) => a.username + ", " + a.email);
      return result[0];
    },
    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filterDept(item) {
      if (!item) return "TBA";
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      if (!item) return "TBA";
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },
    filterAdj(item) {
      if (!item) return "TBA";
      return this.adjusters.filter((adjuster) => adjuster.id === item);
    },
    filtersubRefType(item) {
      return this.subRefType.filter((subRefType) => subRefType.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },
    filterBranch(item) {
      if (!item) return "TBA";
      return this.branch.filter((branch) => branch.id === item);
    },
    filterState(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    showHandlerName(item) {
      const myArray = this.handlers.filter((handler) => handler.id === item);
      let result = myArray.map((a) => a.name);
      return result;
    },
    showBranchName(item) {
      const myArray = this.branch.filter((branch) => branch.id === item);
      let result = myArray.map((a) => a.name);
      return result;
    },
    showRefTypeName(item) {
      const myArray = this.refType.filter((refType) => refType.id === item);
      let result = myArray.map((a) => a.name);
      return result;
    },
    showsubRefTypeName(item) {
      const myArray = this.subRefTypes.filter(
        (subRefTypes) => subRefTypes.id === item
      );
      let result = myArray.map((a) => a.subCode);
      return result;
    },
    showAdjusterName(item) {
      const myArray = this.adjusters.filter((adjuster) => adjuster.id === item);
      let result = myArray.map((a) => a.username);
      return result;
    },
    showInsurerName(item) {
      const myArray = this.insurer.filter((insurer) => insurer.id === item);
      let result = myArray.map((a) => a.name);
      return result;
    },
    showAasbRef(item) {
      const myArray = this.casefiles.filter((id) => id.id === item);
      let result = myArray.map(
        (a) =>
          "AA/" +
          this.filterDeptMap(a.refType) +
          this.filtersubRefTypeMap(a.subRefType) +
          a.id +
          "/" +
          this.yearOnly(a.createdAt) +
          this.filterBranchMap(a.branch)
      );
      return result;
    },
    showFileStatus(item) {
      const myArray = this.stages.filter((stage) => stage.stageCode === item);
      let result = myArray.map((a) => a.name);
      return result;
    },
    filterDeptMap(item) {
      const myArray = this.refType.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.name);
      if (!item) return "";
      return result + "/";
    },
    filterDeptOnly(item) {
      const myArray = this.refType.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.name);
      if (!item) return "";
      return result;
    },
    filtersubRefTypeMap(item) {
      const myArray = this.subRefTypes.filter((subRef) => subRef.id === item);
      let result = myArray.map((a) => a.subCode);
      if (!item) return "";
      return result + "/";
    },
    filterBranchMap(item) {
      const myArray = this.branch.filter((b) => b.id === item);
      let result = myArray.map((a) => a.brCode);
      if (!item) return "";
      return "/" + result;
    },

    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },

    dateDiff(startDateString, endDateString) {
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },

    showDateDiff(item) {
      let start = moment(item);
      let end = moment();
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },

    upperCaseVehicleNo(value) {
      if (!value) return "";
      let varStr = value;
      let result = varStr.toUpperCase();
      return result;
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },

    moment: function (date) {
      return moment(date);
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          // this.branch = response.data;
          this.branch = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          this.states = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveRegions() {
      RegionDataService.getAll()
        .then((response) => {
          this.regions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandlers() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUsers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveAdj() {
      MemberDataService.getAll()
        .then((response) => {
          this.adjusters = response.data.filter((item) =>
            item.roles.some((b) => b.roleCode === "adjuster")
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveBranches();
    this.retrieveStates();
    this.retrieveRegions();
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveStages();
    this.retrieveIns();
    this.retrieveCasefiles();
    // this.checkManagement();
    // this.checkAddCase();
    this.retrieveHandlers();
    this.retrieveUsers();
    this.retrieveAdj();
  },
};
</script>

<style></style>
